@import url("https://fonts.googleapis.com/css?family=Special+Elite");

body {
    font-family: "Special Elite";
}

html {
    font-size: 100%;
}

.p-1{
    padding: 10px;
}

.p-2{
    padding: 20px;
}

.p-3{
    padding: 30px;
}

.pt-1{
    padding-top: 10px;
}
.pt-2{
    padding-top: 20px;
}
.pt-3{
    padding-top: 30px;
}

.pb-1{
    padding-bottom: 10px;
}
.pb-2{
    padding-bottom: 20px;
}
.pb-3{
    padding-bottom: 30px;
}

.m-1{
    margin: 10px;
}

.m-2{
    margin: 20px;
}

.m-3{
    margin: 30px;
}

.mt-1{
    margin-top: 10px;
}
.mt-2{
    margin-top: 20px;
}
.mt-3{
    margin-top: 30px;
}

.mb-1{
    margin-bottom: 10px;
}
.mb-2{
    margin-bottom: 20px;
}
.mb-3{
    margin-bottom: 30px;
}

#loginform {
    max-width: 500px;

    //min-width: 90%;
    width: 90%;
    height: 60%;
    margin: 100px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
}

#headerTitle {
    text-align: center;
    padding: 2rem 0;
    margin: 0;
    font-size: 2rem;
}
.center {
    text-align: center;
}

.counter {
    font-family: "Special Elite";
    font-size: 3rem;
    color:#FF0000
}

.login-code-img{
    max-width: 90%;
}
.row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    max-width: 100%;

}

.row input {
    width: 80%;
    box-sizing: border-box;
    border: none;
    font-size: 1.3rem;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    box-shadow: inset 0px -3px 0px 0px rgba(187, 187, 187, 0.2);
    transition: box-shadow 0.2s ease-in;
}

.row input:focus {
    box-shadow: inset 0px -3px 0px 0px rgba(34, 193, 195, 0.7);
    outline: none;
}

.row input::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder {
    opacity: 0;
}

.row label {
    align-self: start;
    padding-left: 4.5rem;
    padding-bottom: 0.5rem;
    color: rgba(187, 187, 187, 0.9);
}

.row button {
    border-radius: 25px;
    width: 80%;
    height: 40px;
    font-size: 1.3rem;
    color: white;
    font-weight: 700;
    background: rgb(34, 193, 195);
    background: linear-gradient(90deg, #2D4981 0%, purple 100%);
    border: 0px;
    cursor: pointer;
    transition: opacity 0.25s ease-out;
    font-family: "Special Elite";
}

.row button:hover {
    opacity: 0.8;
}

#button {
    padding-bottom: 1.5rem;
}

#facebookIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145802.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
}

#twitterIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
}

#googleIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
}

#iconGroup {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}

#iconGroup a {
    margin: 0 0.6rem;
}

#alternativeLogin {
    text-align: center;
    padding-top: 2rem;
    margin-top: 1.5rem;
}


