.ul-top-movie {
    list-style-type: none;
    font-weight: bold;
}

.ul-top-movie li {
    padding-top: 15px;
}

.ul-top-movie li h1 {
    font-size: 3rem;
    max-width: max-content;
    margin-right: 10px;
    display: inline-block;
    padding: 1px 22px;
    background: #442670;
    color: #fff;
    border-radius: 50%;
}

.ul-top-movie li p {
    padding-left: 80px;
}